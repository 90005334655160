@import url('https://fonts.googleapis.com/css2?family=M+PLUS+2&display=swap');

html, body {
    margin: 0;
    padding: 0;
}

* {
    font-family: 'M PLUS 2', sans-serif;
    margin: 0;
    overflow-x: hidden;
}

h1 {
    font-size : 4em;
    padding: 0;
    margin: 0;
}

h1, h2 ,h3 ,p {
    overflow: hidden;
}

.welcome_content {
    margin: 0 5vw 0 12.5vw;
    padding: 70px 0;
    width: 82.5vw;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.welcome_text {
    font-size: calc(0.15em + 0.8vw + 0.8vh);
}

.full_section {
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow-y: hidden;
}

.section_two_column {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow-y: hidden;
    width: 100vw;
}

.left_column_content {
    width: 20vw;
    padding-left: 7.5vw;
    padding-right: 7.5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-self: center;
    height: 100vh;
}

.right_column_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
    width: 42.5vw;
    padding-right: 15vw; 
    padding-left: 7.5vw;
    overflow: hidden;
    height: 100vh;
}

.arrow_section_up {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 20px;
    margin-left: 170px;
    overflow: hidden;
}

.arrow_section_down {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 40px;
    margin-left: 170px;
    overflow: hidden;
}

/**
* About Me Containers
**/

.profileImage {
    border-radius: 50%;
    border: 3px white solid;
    width: 250px;
    height: 250px;
    display: flex;
    margin-bottom: 20px;
    overflow: hidden;
}

.collage_container {
    display: flex;
    width: 42.5vw;
    height: 50vh;
    flex-direction: column;
    justify-content: center;
}

.collage_image {
    padding-top: 20px;
    max-width: 1700px;
    max-height: 1080px;
}

/** 
* Skills Section
**/

.skills_section {
    display: grid;
    grid-template-columns: repeat(auto-fit, 80px);
    max-width: 45vw;
    min-width: 45vw;
    justify-content: center;
    row-gap: 40px;
    column-gap: 90px;
    overflow-x: visible;
}

.skills_section .skill_icon {
    font-size: 80px;
}

.skill_icon {
    color: black;
}

.skill_component {
    display: flex;
    width: 120px;
    flex-direction: column;
    align-items: center;
    overflow: visible;
}

.skill_component p {
    color: black;
    font-size: 0.75em;
}

.skill_component h3 {
    color: black;
    font-size: 1.5em;
}

/**
* CSS for History Section
**/

.history_window {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
    width: 50vw;
    padding: 5vh 7.5vw;
    height: 100vh;
    overflow: hidden;
}

.timeline_container {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 80vh;
    width: 50vw;
    padding: 20px 0;
    overflow-y: auto;
    overflow-x: hidden;
}

.timeline_container::-webkit-scrollbar {
    display: none;
}

.timeline_container::after {
    background-color: #1DB954;
    content: "";
    position: absolute;
    left: calc(50% + 0.52em);
    width: 4px;
    /* This calculates the green centre backbone line, I forgot the math but its 24vh * (number of elements) + 80px */
    height: calc(24vh * 7 + 80px);
}

.timeline_bookend {
    display: flex;
    flex-direction: row;
    width: 50vw;
}

.timeline_bookend_mark {
    background-color: #FFFFFF;
    border: 3px solid #1DB954;
    position: absolute;
    align-self: center;
    left: 50%;
    width: 15px;
    height: 15px;
    z-index: 100;
    transform: rotateZ(45deg);
}

.timeline_element {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    width: 50vw;
    margin: 12vh 0;
}

.timeline_element:nth-child(odd) {
    justify-content: flex-end;
}

.timeline_content {
    width: 18vw;
    height: 14vh;
    display: flex;
    flex-direction: column;
    transform: translateY(-75px);
    position: absolute;
    background-color: #FFFFFF;
    border-radius: 1.5vw;
    padding: 1vw;
    font-size: calc(0.2em + 0.7vw);
}

.timeline_mark {
    background-color: #FFFFFF;
    border: 3px solid #1DB954;
    border-radius: 50%;
    position: absolute;
    align-self: center;
    left: 50%;
    width: 15px;
    height: 15px;
    z-index: 100;
}

.timeline_header_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
}

.timeline_header_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 12vw;
}

.timeline_image {
    margin: 0 1vw;
    max-width: 100%;
    max-height: 100%;
}

.timeline_text_container {
    margin: 1vh 1vw;
    width: 16vw;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

/**
* CSS for Projects (Mainly Carousel)
**/

.project_right_column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55vw;
    height: 100vh;
    padding-right: 5vw;
    overflow: hidden;
}

.carousel_slide {
    padding: 0 5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    overflow: hidden;
    height: 80vh;
    width: 35vw;
    justify-content: space-between;
} 

.video_container {
    max-width: 45vw;
    max-height: 50vh;
}

.slide_image {
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    margin: 20px 0;
}

.slide_two_image {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}

.carousel_slide h1 {
    color: #1DB954;
    margin-bottom: 10px;
    overflow: hidden;
    font-size: 2.5em;
    text-align: center;
    margin-top: 20px;
}

.carousel_slide p {
    color: black;
    margin: 10px 0;
    overflow: hidden;
    text-align: justify;
    text-justify: inter-word;
}

.carousel_slide li {
    display: list-item;
    list-style-type: circle;
    margin-top: 8px;
    color: black;
    overflow: hidden;
}

.carousel_slide ul {
    overflow: hidden;
}

.carousel_center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    overflow: hidden;
}

.carousel_slider {
    width: 45vw;
    height: 80vh;
    background-color: transparent;
    overflow: hidden;
}

.carousel_wrapper {
    display: flex;
    flex-direction: row;
}

.carousel_dotgroup {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 10px;
    color: black;
}

.carousel__dot {
    height: 15px;
    width: 15px;
    margin: 3px;
    border-radius: 50%;
    border: 1px solid black;
}

.carousel__dot--selected {
    background-color: #1DB954;
}

.button_back {
    margin-right: 20px;
    margin-bottom: 5vh;
    background-color: transparent;
    opacity: 1;
    border: 0;
}

.button_next {
    margin-left: 20px;
    margin-bottom: 5vh;
    background-color: transparent;
    opacity: 1;
    border: 0;
}

/** 
* CSS for the footer component (Footer.JS)
**/

.footer_bar {
    width: 100vw;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #1DB954;
}

.footer_content_left {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: left;
    overflow: hidden;
}

.footer_link {
    margin: 0 30px;
    display: inline-flex;
    overflow: hidden;
}

.footer_content_right {
    height: 100%;
    font-size: 1.1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 5vw;
    overflow: hidden;
}

.footer_icon {
    margin: 0 5px;
    vertical-align: middle;
}

/**
* Spotify CSS
**/

.spotify_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50vw;
    height: 80vh;
}

.spotify_button {
    border-width: 2px;
    padding: 5px;
    align-self: center;
    width: 100px;
    border-color: #1DB954;
    background-color: transparent;
    border-radius: 0.4em;
    color: white;
    font-size: 1em;
    font-weight: bold;
    margin-top: 30px;
}

/**
* CSS for the Scrollbar
**/

@media screen {
    
    ::-webkit-scrollbar {
        width: 0.5vw;
    }

    ::-webkit-scrollbar-thumb {
        background-color: white;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #bbbbbb;
    }

    ::-webkit-scrollbar-track {
        background-color: black;
    }

}

.particle_background  {
    opacity: 1;
    z-index: -1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1.4s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/**
* Resizing CSS for mobile screens
**/

@media only screen and (max-width: 800px) {

    h1 {
        font-size: 48px;
    }

    h2 {
        font-size: 24px;
    }

    h3 {
        font-size: 16px;
    }

    .full_section {
        width: 100vw;
        height: 200vh;
        background: #FFFFFF !important;
    }

    .section_two_column {
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: 200vh;
    }

    .animated {
        width: 100vw;
    }

    .left_column_content {
        width: 80vw;
        height: 80vh;
        padding: 10vh 10vw;
    }

    .right_column_content {
        width: 80vw;
        height: 80vh;
        display: flex;
        padding: 10vh 10vw;
        flex-direction: column;
        align-content: center;
        justify-content: center;
    }

    .right_column_content h1 {
        font-size: 48px;
    }
    
    .right_column_content h2 {
        font-size: 16px;
    }

    .profileImage {
        width: 200px;
        height: 200px;
    }

    .collage_container {
        width: 75vw;
    }

    .skills_section {
        align-self: center;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, 60px);
        width: 100%;
        justify-content: center;
        row-gap: 20px;
        column-gap: 40px;
        overflow: hidden;
    }

    .skills_section .skill_icon {
        font-size: 60px;
    }

    .skill_component {
        width: 60px;
    }

    .skill_component p {
        color: black;
        font-size: 6px;
    }
    
    .skill_component h3 {
        color: black;
        font-size: 13px;
    }

    /* Need to make the whole thing one panel without being a child scrollable window as that is kinda broken on mobile */
    .history_section {
        height: calc(100vh + 26vh * 8 + 80px);
    }

    /* On mobile, we need to accurately define the full length of this component I don't know why, so 26vh * n+1 elements + 80px */
    .history_window {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        justify-self: flex-start;
        width: 100vw;
        padding: 0 7.5vw;
        height: calc(26vh * 8 + 80px);
        overflow: hidden;
    }
    
    .timeline_container {
        display: flex;
        flex-direction: column;
        position: absolute;
        height: calc(26vh * 8 + 80px);
        width: 80vw;
        padding: 10vh 0;
        overflow: hidden;
    }

    .timeline_container::after {
        height: calc(26vh * 7 + 80px);
    }

    .timeline_element {
        width: 80vw;
        margin: 13vh 0;
    }

    .timeline_content {
        width: 24vw;
        height: 20vh;
        font-size: calc(0.4em + 0.6vw);
    }

    .timeline_header_container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }
    
    .timeline_header_text {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1vh;
        width: 20vw;
        height: 8vh;
    }
    
    .timeline_image {
        margin-top: 1vh;
        max-width: 14vw;
        max-height: 4vh;
    }
    
    .timeline_text_container {
        margin: 0 1vw;
        width: 20vw;
        text-align: center;
        align-items: center;
    }

    /* .long_text {
        font-size: 0.8em;
    } */

    .project_right_column {
        width: 100vw;
        height: 100vh;
        padding: 0;
    }

    .carousel_slider {
        width: 150vw;
        overflow-x: hidden;
    }

    .carousel_slide {
        width: 65vw;
        padding: 0;
    }

    .carousel_slide h1 {
        font-size: 26px;
    }
    
    .carousel_slide p {
        font-size: 14px;
        text-align: center;
    }

    .carousel_slide li {
        font-size: 14px;
        text-align: left;
    }

    .button_next {
        margin-left: 0;
        margin-right: 20px;
    }

    .carousel__dot {
        height: 10px;
        width: 10px;
        font-size: 4px;
    }

    .carousel_dotgroup {
        text-align: center;
        padding-top: 5px;
        padding-bottom: 10px;
        color: black;
    }

    .spotify_container {
        width: 80vw;
    }

    .footer_bar {
        height: 50px;
    }
    
    .footer_content_left {
        height: 100%;
        width: 80vw;
        margin-left: -20px;
    }
    
    .footer_link {
        margin-right: 5px;
    }
    
    .footer_content_right {
        display: none;
    }
    

}